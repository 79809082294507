var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"type-selection"},[_c('h2',{class:_vm.darkMode && _vm.$i18n.locale === 'en'
        ? 'type-selection-title-dark'
        : 'type-selection-title'},[_vm._v("\n    "+_vm._s(_vm.$t('whoAreYou.title'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"type-selection-content"},[_c('nuxt-link',{staticClass:"content-container",attrs:{"to":_vm.localePath('/collections/country-gifts-for-her')}},[_c('ThemeImage',{staticClass:"content-image",staticStyle:{"width":"100%","height":"auto"},attrs:{"src":_vm.isMobile
            ? require('@/assets/img/home-gender-ladies-image-mobile.webp')
            : _vm.assetURL(_vm.ladies.lg.media),"alt":"Ladies Image Desktop","width":"400","height":"267","full-size":""}}),_vm._v(" "),_c('span',{staticClass:"content-text"},[_vm._v(_vm._s(_vm.$t('whoAreYou.ladies')))])],1),_vm._v(" "),_c('nuxt-link',{staticClass:"content-container",attrs:{"to":_vm.localePath('/collections/hand-selected-gifts-for-him')}},[_c('ThemeImage',{staticClass:"content-image",staticStyle:{"width":"100%","height":"auto"},attrs:{"src":_vm.isMobile
            ? require('@/assets/img/home-gender-mens-image-mobile.webp')
            : _vm.assetURL(_vm.mens.lg.media),"alt":"Mens Image Desktop","width":"400","height":"267","full-size":""}}),_vm._v(" "),_c('span',{staticClass:"content-text"},[_vm._v(_vm._s(_vm.$t('whoAreYou.men')))])],1),_vm._v(" "),_c('nuxt-link',{staticClass:"content-container",attrs:{"to":_vm.localePath('/collections/hand-selected-gifts-for-kids')}},[_c('ThemeImage',{staticClass:"content-image",staticStyle:{"width":"100%","height":"auto"},attrs:{"src":_vm.isMobile
            ? require('@/assets/img/home-gender-kids-image-mobile.webp')
            : _vm.assetURL(_vm.kids.lg.media),"alt":"Kids Image Desktop","width":"400","height":"267","full-size":""}}),_vm._v(" "),_c('span',{staticClass:"content-text"},[_vm._v(_vm._s(_vm.$t('whoAreYou.kids')))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }