//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { assetURL } from '@/plugins/utilities'
export default {
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile: null,
      ladies: {
        lg: {
          media: {
            cf_path: '/GLMnWhEP2xkzsOzI-gender-links-ladies/2400w',
            extension: 'jpg',
            format: 'image/jpeg',
          },
        },
        sm: {
          media: {
            cf_path: '/Q8gwkn3SIsgmDnbS-home-ladies-mobile/400w',
            extension: 'jpg',
            format: 'image/jpeg',
          },
        },
      },
      mens: {
        lg: {
          media: {
            cf_path: '/waVcnAU1NO6QGzQt-gender-links-mens/2400w',
            extension: 'jpg',
            format: 'image/jpeg',
          },
        },
        sm: {
          media: {
            cf_path: '/o5we7LLZEFvAXRUn-home-mens-mobile/400w',
            extension: 'jpg',
            format: 'image/jpeg',
          },
        },
      },
      kids: {
        lg: {
          media: {
            cf_path: '/xzLAPcevqHL85KN3-gender-links-kids/2400w',
            extension: 'jpg',
            format: 'image/jpeg',
          },
        },
        sm: {
          media: {
            cf_path: '/EUkunO9wqzhDrBVa-home-kids-mobile/400w',
            extension: 'jpg',
            format: 'image/jpeg',
          },
        },
      },
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 799
  },
  methods: {
    assetURL,
  },
}
